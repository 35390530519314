<template>
  <div class="cmp-tabs">
    <ul v-if="computedTabs.length > 0" class="tabs">
      <li v-for="tab in computedTabs" :key="tab.id" :class="{'active': tab.active }" class="tab">
        <a @click="switchTab(tab.id)">{{ tab.description }}</a>
      </li>
    </ul>

    <div class="tab-panels">
      <div v-for="tab in computedTabs" :key="tab.id" :class="{'active': tab.active }" class="cmp-slot tab-slot tab-panel">
        <transition name="fadein">
        <div class="tab-panel-inner" v-if="tab.active">
          <slot :name="[tab.id]"></slot>
        </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: { type: Array, required: false }
  },
  computed: {
    computedTabs () {
      return this.tabs.filter(filter => filter.visible);
    }
  },
  methods: {
    switchTab (tabId) {
      this.computedTabs.forEach(tab => {
        if (tab.id === tabId) {
          tab.active = true;
        } else {
          tab.active = false;
        }
      });
    }
  }
}
</script>

<style>

</style>
