<template>
  <div class="cmp-product-list-item utlz-col-sm-4" :class="'utlz-col-lg-' + blockSize">
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">
      <template v-if="product.prices != 'undefined'">
        <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
      </template>
      <div class="utlz-col-3 utlz-col-sm-12 img-container">

          <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control>

        <!-- BESPOKE CAP: Changed image banners to a seperate control, should be in a future release -->
        <template
          v-if="screenWidth >= 576">
          <utlz-product-banners :banners="product.productImageBanners"></utlz-product-banners>
        </template>
        <!-- END BESPOKE CAP -->

          <figure class="img-square prod-img">
            <a :href="product.targetUrl" class="clickthrough">
              <img class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" />
            </a>
          </figure>
      </div>

      <div class="utlz-col-9 utlz-col-sm-12 text-container">
        <a :href="product.targetUrl" class="clickthrough">

          <!-- BESPOKE CAP: Changed image banners to a seperate control, should be in a future release -->
          <template v-if="screenWidth <= 576">
            <utlz-product-banners :banners="product.productImageBanners"></utlz-product-banners>
          </template>
          <!-- END BESPOKE CAP -->
          
          <div class="product-code">
            <span class="value">{{ product.id }}</span>
          </div>
          <span class="prod-brand">{{ product.customStrings.BRAND }}</span>
          <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>          
        </a>

        <div class="ph-product-price" v-if="product.prices.length">
          <utlz-product-price
            :prices="product.prices[0]"
            :parentClassRef="'ref-product-list-item'"
          ></utlz-product-price>
        </div>

        
        <!-- BESPOKE -->
        <template v-if="product.customStrings.RECOMMENDED_PRICE">
          <div class="recom-price-label">{{ labelRecomPrice }} {{ product.customStrings.RECOMMENDED_PRICE }}</div>
        </template>
        <div class="ean-code-label">{{ labelEanCode }} {{ product.customStrings.BAR_CODE }}</div>
        <!-- END BESPOKE -->

        <utlz-product-variant
          v-if="product.variantKey && groupProductsList != 0 && showOrderProductControl"
          :product="computedProduct"
          :productVariants="product.productVariants"
          @selectedProduct="selectedProduct($event)"
        ></utlz-product-variant>

        <div class="ph-order-product">
          <transition name="fade">
            <utlz-order-product
              v-if="showOrderProductControl && product.prices.length"
              :product="computedProduct"
              :parentClassRef="'ref-product-list-item'"
            >
            </utlz-order-product>
          </transition>

          <!-- BESPOKE -->
          <!-- <transition name="fade">
            <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
              <i class="fa fa-warning"></i>
              <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
            </p>
          </transition> -->
          <!-- END BESPOKE -->
        </div>

        <!-- If product has variants, disable stock and
        use stock in OrderProduct.vue component which is variant specific -->
        
        <!-- BESPOKE -->
        <!-- <utlz-product-stock
          v-if="!product.variantKey || groupProductsList == 0"
          :stock="product.stock"
          :stockTotal="product.stock"
        ></utlz-product-stock> -->
        <!-- END BESPOKE -->

      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Product } from 'src/models/product.model';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
// BESPOKE
// Added product banners control, which should be in a future release
import ProductBanners from 'src/components/webshop/banners/ProductBanners.vue';
// END BESPOKE
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-product-variant': ProductVariant,
    'utlz-favorites-control': FavoritesControl,
    // BESPOKE
    // Added product banners control, which should be in a future release
    'utlz-product-banners': ProductBanners
    // END BESPOKE
  },
  props: {
    product: { type: Product, required: true },
    blockSize: { type: Number, required: false, default: 3 }
  },
  data () {
    return {
      isSalesAction: this.product.prices[0],
      activeProductVariantId: null,
      // BESPOKE
      labelRecomPrice: window.vue.translations.label.label_free_text1,
      labelEanCode: window.vue.translations.label.label_ean_code
      // END BESPOKE
    };
  },
  computed: {
    ...mapState('elastic', ['layoutType']),
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList', 'screenWidth']),
    showOrderProductControl () {
      if (!this.showPrices) {
        return false;
      } else if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.product.stock !== null) {
          return this.stockLimit && this.product.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showOutOfStockMessage () {
      if (!this.stockLimit) {
        return false;
      } else if (this.product.stock !== null && this.showOrderProduct) {
        return this.product.stock.stockTotal <= 0;
      } else {
        return false;
      }
    },
    computedProduct () {
      if (!this.product.variantKey) {
        return this.product;
      } else {
        if (this.activeProductVariantId) {
          const index = this.product.productVariants.findIndex(product => {
            return product.id === this.activeProductVariantId;
          });
          return this.product.productVariants[index];
        } else {
          return this.product;
        }
      }
    }
  },
  methods: {
    selectedProduct (id) {
      this.activeProductVariantId = id;
    }
  }
};
</script>

<style>
</style>
