<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row">
    <!-- BESPOKE CAP: Changed class to col-md-5 -->
      <div class="utlz-col-md-5 column-left">
        <!-- END BESPOKE CAP -->

        <!-- BESPOKE CAP: Added product image banners control, this should be in a future release -->
        <utlz-product-banners :banners="productDetails.productImageBanners"></utlz-product-banners>
        <!-- END BESPOKE CAP -->
        <utlz-gallery :product="productDetails"></utlz-gallery>

      </div>
      <!-- BESPOKE CAP: Changed class to col-md-7 -->
      <div class="utlz-col-md-7 column-right product-details-column">
        <!-- END BESPOKE CAP -->
          <div class="product-information">
            <!-- BESPOKE -->
            <span class="product-brand">{{ productDetails.customStrings.BRAND }}</span>
            <!-- END BESPOKE -->
            <h1>{{productDetails.shortDescription}}</h1>
            <div class="product-code">
                <span>{{productDetails.id}}</span>
            </div>

            <template v-if="productDetails.subTitle.length">
              <div class="subtitle">
                <span>{{ productDetails.subTitle }}</span>
              </div>
            </template>

            <template>
              <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
                <div ref="productDescription" class="prod-desc-wrapper">
                  <div class="product-long-description" v-html="productDetails.longDescription">
                  </div>
                </div>
              </div>
              <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
                <div v-if="!expanded" class="read-more">
                  <span v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </div>
                <div v-if="expanded" class="read-less">
                  <span v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
                  <i class="fa fa-angle-up" aria-hidden="true"></i>
                </div>
              </a>
            </template>

            <template v-if="productAdvantages.length">
              <div class="product-advantages">
                {{ productAdvantages }}
              </div>
            </template>

            <div class="ph-product-price" v-if="productDetails.prices.length">
                <utlz-product-price
                    :prices="productDetails.prices[0]"                    
                ></utlz-product-price>
            </div>      

            <!-- BESPOKE Added recommended price -->
            <template v-if="productDetails.customStrings.RECOMMENDED_PRICE">
              <div class="recom-price-label">{{ labelRecomPrice }}: {{ productDetails.customStrings.RECOMMENDED_PRICE }}</div>
            </template>
            <div class="ean-code-label">{{ labelEanCode }}: {{ productDetails.customStrings.BAR_CODE }}</div>
            <!-- END BESPOKE -->

            <utlz-group-dropdown :variants="productVariants" />

            <utlz-product-stock
              :product="productDetails"
              :stock="productDetails.stock"
              :stockTotal="productDetails.stock"
            ></utlz-product-stock>

            <!-- BESPOKE CAP: Added :useTextButton -->
            <div class="order-product">
              <utlz-order-product
                v-if="showOrderProductControl && productDetails.prices.length"
                :product="productDetails"
                :useTextButton="true"
                :useOrderComment="useOrderLineComments"
                >
                </utlz-order-product>

                <transition name="fade">
                  <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                    <i class="fa fa-warning"></i>
                    <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                  </p>
                </transition>
            </div>
            <!-- END BESPOKE CAP -->

            <div class="favorite">
              <!-- BESPOKE CAP: Changed v-if to userLoggedOn -->
              <utlz-favorites-control v-if="userLoggedOn" :product="productDetails" :useDescription="true"></utlz-favorites-control>
              <!-- END BESPOKE CAP -->
            </div>
          </div>
      </div>


      <!-- BESPOKE CAP: Moved tabs underneath the lightbox -->
      <div class="utlz-col-12 margin-bottom-30 tabs-row">
        <utlz-tabs :tabs="tabs">
          <!-- BESPOKE CAP: Grouped specification under a tab and disabled header -->
          <template v-slot:specs>
            <div class="product-specifications">
            <!-- <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2> -->
            <div class="spec-list" :class="{'collapse-properties': productProperties.length > 10, 'expand': showProperties }">
              <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
                <div class="spec-desc">
                  {{prop.key}}
                </div>
                <div class="spec-value">
                  {{prop.value}}
                </div>
              </div>
            </div>
            <!-- BESPOKE CAP -->  
            <template v-if="productProperties.length > 10">
              <div @click="showProperties = !showProperties" class="read-more">
                <span v-translation="{ type: 'label', code: 'label_expand_content' }" class="expand-btn" :class="{'hidden': showProperties === true}">
                 
                </span>
                <span v-translation="{ type: 'label', code: 'label_collapse_content' }" class="collapse-btn" :class="{'hidden': showProperties === false}">
                  
                </span>
              </div>
            </template>
            <!-- END BESPOKE CAP -->
          </div>
          </template>
          <!-- END BESPOKE CAP -->        

          <template v-slot:videos v-if="videos.length">
            <div class="product-videos">
              <utlz-product-videos :videos="videos"></utlz-product-videos>
            </div>
          </template>

          <template v-slot:documents v-if="documents.length">
            <div class="product-documents">
              <utlz-product-documents :documents="documents"></utlz-product-documents>
            </div>
          </template>

        </utlz-tabs>
        <!-- END BESPOKE CAP -->
      </div>

      <!-- BESPOKE CAP: Disable matrix -->
      <!-- <div v-if="showOrderProductControl && productDetails.prices.length && productVariants.length" class="utlz-col-md-12">
        <utlz-group-matrix :variants="productVariants" />
      </div> -->
      <!-- END BESPOKE CAP -->

      <div v-if="relatedProducts.upSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.upSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleUpSelling"
        ></utlz-product-carousel>
      </div>

      <div v-if="relatedProducts.crossSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.crossSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleCrossSelling"
        ></utlz-product-carousel>
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductGroupMatrix from 'src/components/webshop/products/product-group/ProductGroupMatrix.vue';
import ProductGroupDropdown from 'src/components/webshop/products/product-group/ProductGroupDropdowns.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
import Gallery from 'src/components/ui/gallery/Gallery.vue';
import Tabs from 'src/components/ui/tabs/Tabs.vue';
import ProductVideos from 'src/components/webshop/products/product-videos/ProductVideos.vue';
import ProductDocuments from 'src/components/webshop/products/product-documents/ProductDocuments.vue';
import ProductCarousel from 'src/components/webshop/products/product-carousel/ProductCarousel.vue';
// BESPOKE
// Added product banners control, which should be in a future release
import ProductBanners from 'src/components/webshop/banners/ProductBanners.vue';
// END BESPOKE
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-product-stock': ProductStock,
    'utlz-group-matrix': ProductGroupMatrix,
    'utlz-group-dropdown': ProductGroupDropdown,
    'utlz-favorites-control': FavoritesControl,
    'utlz-gallery': Gallery,
    'utlz-tabs': Tabs,
    'utlz-product-carousel': ProductCarousel,
    'utlz-product-videos': ProductVideos,
    'utlz-product-documents': ProductDocuments,
    // BESPOKE
    // Added product banners control, which should be in a future release
    'utlz-product-banners': ProductBanners
    // END BESPOKE
  },
  data () {
    return {
      expanded: false,
      productDescriptionHeight: 0,
      titleUpSelling: window.vue.translations.title.title_product_relations,
      titleCrossSelling: window.vue.translations.title.title_product_relations_cross,
      tabs: [
        // BESPOKE CAP
        // Added product specs and produc advantages as tab and removed description from tabs
        { id: 'specs', description: window.vue.translations.title.title_specifications, active: false, visible: false },
        // END BESPOKE CAP
        { id: 'documents', description: window.vue.translations.label.label_tab_product_documents, active: false, visible: false },
        { id: 'videos', description: window.vue.translations.label.label_tab_product_videos, active: false, visible: false }
      ],
      // BESPOKE CAP
      showProperties: false,
      labelRecomPrice: window.vue.translations.label.label_free_text1,
      labelEanCode: window.vue.translations.label.label_ean_code
      // END BESPOKE CAP
    }
  },
  watch: {
    // BESPOKE CAP
    // Added watcher for specs tab and changed indexed to correspond with positions of tabs in data.tabs array    
    productProperties (val) { this.tabs[0].visible = val.length > 0; this.setActiveTabs(); },
    documents (val) { this.tabs[1].visible = val.length > 0; this.setActiveTabs(); },
    videos (val) { this.tabs[2].visible = val.length > 0; this.setActiveTabs(); }
    // END BESPOKE CAP
  },
  computed: {
    ...mapState('productDetails', ['productDetails', 'productVariants', 'relatedProducts', 'videos']),
    // BESPOKE CAP
    // Added userLoggedOn
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'relatedProductBlockSize', 'userLoggedOn', 'screenWidth', 'language']),
    // END BESPOKE CAP
    ...mapGetters('productDetails', ['documents']),
    useCollapse () {
      // BESPOKE CAP
      // Changed height to 150
      return this.productDescriptionHeight > 150;
      // END BESPOKE CAP
    },
    showOrderProductControl () {
      if (!this.showPrices) {
        return false;
      } else if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.productDetails.stock !== null) {
          return this.stockLimit && this.productDetails.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      // BESPOKE CAP-84
      // If a key is used twice, combine the property values for that key and seperate with a |
      var properties = [];
      Object.keys(this.productDetails.properties).forEach(x => {
        
        if (x.toLowerCase().trim() === 'merk (av)') {
          return;
        }

        var labelYes = "Ja";
        var labelNo = "Nee";

        var lang = this.language.toLowerCase();

        if (lang === 'de') {
          labelYes = "Ja";
          labelNo = "Nein";
        }

        if (lang === 'en') {
          labelYes = "Yes";
          labelNo = "No";
        }         

        if (this.productDetails.properties[x].toLowerCase() === 'true') {
          this.productDetails.properties[x] = labelYes;
        } else if (this.productDetails.properties[x].toLowerCase() === 'false') {
          this.productDetails.properties[x] = labelNo;
        }

        var existingProperty = properties.find(y => y.key.trim() === x.trim());        
        if (existingProperty !== undefined) {
          existingProperty.value = existingProperty.value + " | " + this.productDetails.properties[x];
        } else {
          properties.push({ key: x, value: this.productDetails.properties[x] });
        }


      });
      return properties;
      // END BESPOKE CAP-84
    },
    images () {
      let images = [];

      images.push(this.productDetails.imageUrl)

      this.productDetails.images.forEach(element => {
        images.push(element);
      });

      return images
    },
    productAdvantages() {
      var advantages = this.productDetails.customStrings["ADV_" + this.language.toUpperCase()];
      return advantages != undefined ? advantages : ""
    }
  },
  updated () {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }
  },
  created () {
    this.initDetails();
  },
  methods: {
    ...mapActions('productDetails', ['initDetails']),
    setActiveTabs() {
      var activeTab = false;
      this.tabs.forEach(tab => {
        if (tab.visible && !activeTab) {
          tab.active = true;
          activeTab = true;
        } else {
          tab.active = false;
        }
      });
    }
  }
}
</script>

<style>

</style>
